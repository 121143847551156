import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/reviewPage.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ReviewHeader = makeShortcode("ReviewHeader");
const ReviewFooter = makeShortcode("ReviewFooter");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <ReviewHeader data={props.pageContext.frontmatter} mdxType="ReviewHeader" />
    <h1>{`Overall`}</h1>
    <p>{`Overall we enjoyed it, but given the amount of better options we wouldn't suggest it.
However, if this is the only Poke close to you, you won't be truly disappointed.
Mediocre Poke is always better than no Poke.`}</p>
    <h1>{`Key Points:`}</h1>
    <ul>
      <li parentName="ul">{`Didn’t get a ton of ginger to begin with or after asking for more.`}</li>
      <li parentName="ul">{`The amount of sauce left us wanting more, so bring your own sriracha-mayo to add on. `}</li>
      <li parentName="ul">{`Gives separate plastic bags and multiple kinds of disposable silverware and chopsticks. 👎 😠`}</li>
      <li parentName="ul">{`Lackluster service.`}</li>
      <li parentName="ul">{`You can't see the food before or as they are making it. 👀`}</li>
    </ul>
    <h1>{`Biggest Suggestion`}</h1>
    <p>{`Invest time into connecting with your customers wants, and step up your eco-conciousness! ♻️`}</p>
    <ReviewFooter data={props.pageContext.frontmatter} mdxType="ReviewFooter" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      